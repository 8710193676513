import { useState } from "react";
import Col from "react-bootstrap/Col";
import CurrentQuestion from "./CurrentQuestion";
import InfoModal from "./InfoModal";
import GenerateBook from "./GenerateBook";
import { useDocument } from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";
import { firestore, auth } from "../firebase/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import WaitingForReview from "./WaitingForReview";
import AddName from "./AddName";
import AddCover from "./AddCover";
import BookReview from "./BookReview";
import WaitingForPrint from "./WaitingForPrint";
import AddShippingAddress from "./AddShippingAddress";
import BookShipped from "./BookShipped";
import Upsell from "./Upsell";
import FirstUpsell from "./FirstUpsell";
import { useTranslation } from "react-i18next";

const MainActivity = ({ userQuestions: availableQuestions }) => {
  const [answerSuccessModal, setAnswerSuccessModal] = useState(false);
  const [upsell, setUpsell] = useState(true);
  const [firstUpsell, setFirstUpsell] = useState(true);

  const { t } = useTranslation();
  const [user] = useAuthState(auth);
  const [userDataRaw, loading] = useDocument(
    doc(firestore, "users", user?.uid),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    },
  );

  const userData = userDataRaw?.data();
  const firstUnansweredQuestion = availableQuestions?.find(
    (question) => question?.answers?.length === 0,
  );
  const allQuestionsAnswered =
    availableQuestions?.filter((question) => question?.answers?.length > 0)
      ?.length === 50;

  const renderContent = () => {
    if (loading) {
      return null;
    }
    if (!userData?.name) {
      return <AddName />;
    }
    if (firstUnansweredQuestion?.questionId === "2" && firstUpsell) {
      return <FirstUpsell onPress={() => setFirstUpsell(false)} />;
    }
    if (userData?.ship_book) {
      return <BookShipped userData={userData} />;
    }
    if (userData?.ready_for_print_book) {
      if (!userData?.cover) {
        return <AddCover />;
      }
      if (!userData?.shipping_address) {
        return upsell ? (
          <Upsell onPress={() => setUpsell(false)} />
        ) : (
          <AddShippingAddress />
        );
      }

      return <WaitingForPrint />;
    }
    if (userData?.review_book) {
      return <BookReview />;
    }
    if (userData?.generate_book) {
      return <WaitingForReview />;
    }
    if (allQuestionsAnswered) {
      return <GenerateBook />;
    }
    if (firstUnansweredQuestion) {
      return (
        <CurrentQuestion
          lastAvailableQuestion={firstUnansweredQuestion}
          onSuccess={() => setAnswerSuccessModal(true)}
        />
      );
    }
  };

  return (
    <Col className="main-activity-container">
      {renderContent()}
      <InfoModal
        visible={answerSuccessModal}
        onHide={() => setAnswerSuccessModal(false)}
        title={t("dashboardScreen.answerSuccessfullyRecorded")}
        text={t("dashboardScreen.nicelyDone")}
      />
    </Col>
  );
};

export default MainActivity;
