import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import {
  collection,
  limit,
  query,
  orderBy,
  where,
  getDocs,
} from "firebase/firestore";
import { useState } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { firestore, updateUser } from "../firebase/firebase";
import { format } from "date-fns";
import Spinner from "react-bootstrap/Spinner";
import { useGenerateBookQA } from "../utils/book";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import ReviewModal from "./ReviewModal";
import { getStructuredUserQuestionsWithAnswers } from "../utils/dataHelpers";
import ClientInfoModal from "./ClientInfoModal";
import BookReviewModal from "./BookReviewModal";
import ShipModal from "./ShipModal";
import PrintModal from "./PrintModal";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../firebase/firebase";
import { PRODUCTION_STEPS } from "../utils/data";
import Dropdown from "react-bootstrap/Dropdown";
import SendToPrintModal from "./SendToPrintModal";
import { useTranslation } from "react-i18next";
import { FaSearch } from "react-icons/fa";

const UsersTable = () => {
  const [reviewModal, setReviewModal] = useState(false);
  const [clientInfoModal, setClientInfoModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [shipModal, setShipModal] = useState(false);
  const [printModal, setPrintModal] = useState(false);
  const [sendToPrintModal, setSendToPrintModal] = useState(false);
  const [filter, setFilter] = useState(false);
  const [users, setUsers] = useState(null);
  const [userForGeneration, setUserForGeneration] = useState({});
  const [usersLoading, setUsersLoading] = useState(false);
  const [startChapter, setChapterStart] = useState(1);
  const [endChapter, setChapterEnd] = useState(50);
  const [searchUser, setSearchUser] = useState(null);
  const [model, setModel] = useState("gpt-3.5-turbo");

  const [currentUser] = useAuthState(auth);
  const { i18n } = useTranslation();

  const emailRepliesQuery = query(
    collection(firestore, "email-replies"),
    userForGeneration?.email && where("sender", "==", userForGeneration?.email),
  );
  const [emailReplies, emailRepliesLoading] = useCollection(emailRepliesQuery, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });
  const [questions] = useCollection(collection(firestore, "questions"), {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  const fetchUsersData = async ({ f, s }) => {
    try {
      setUsersLoading(true);

      console.log(s);

      const filterFunction = () => {
        if (s) {
          return where("email", "==", s);
        }
        if (f === "custom") {
          return orderBy("email", "desc");
        }
        if (f) {
          return orderBy(f, "desc");
        }
        return null;
      };

      const usersQuery = query(
        collection(firestore, "users"),
        filterFunction(),
        // limit(20)
      );
      const querySnapshot = await getDocs(usersQuery);
      const usersForTable = [];

      querySnapshot?.forEach((doc) => {
        const data = doc.data();

        // if (data?.email?.includes("kilo.health")) {
        //   return;
        // }

        usersForTable.push({
          ...data,
          uid: doc.id,
          generateBookDate: data?.generate_book
            ? format(data?.generate_book * 1000, "yyy MM dd")
            : null,
          reviewBookDate: data?.review_book
            ? format(data?.review_book * 1000, "yyy MM dd")
            : null,
          readyForPrintBookDate: data?.ready_for_print_book
            ? format(data?.ready_for_print_book * 1000, "yyy MM dd")
            : null,
          printBookDate: data?.print_book
            ? format(data?.print_book * 1000, "yyy MM dd")
            : null,
          shipBookDate: data?.ship_book
            ? format(data?.ship_book * 1000, "yyy MM dd")
            : null,
        });
      });

      const modifiedUsersForTable = f
        ? [...usersForTable]?.filter((user) => {
            const currentProductionStepIndex = PRODUCTION_STEPS.indexOf(f);
            if (!user?.[PRODUCTION_STEPS[currentProductionStepIndex + 1]]) {
              return user;
            }
            return false;
          })
        : [...usersForTable];

      setUsers(modifiedUsersForTable);
    } catch (e) {
      console.log(e);
    } finally {
      setUsersLoading(false);
    }
  };

  const structuredUserQuestionsWithAnswers =
    getStructuredUserQuestionsWithAnswers({
      questions,
      answers: emailReplies,
      userEmail: userForGeneration?.email,
      bookQaPhotos: userForGeneration?.book_qa_photos,
      language: userForGeneration?.language,
    });

  const {
    generate,
    getData,
    loading,
    data: bookData,
  } = useGenerateBookQA(
    structuredUserQuestionsWithAnswers,
    () => setReviewModal(true),
    startChapter,
    endChapter,
    userForGeneration?.book_qa_data,
    model,
    userForGeneration?.language || i18n.resolvedLanguage,
  );

  const handleSaveGeneratedAnswers = () => {
    const generatedData = bookData
      .map((i) => ({
        questionId: Number(i?.questionId),
        text: i?.answer || "skip",
        verified: false,
      }))
      ?.reduce((acc, curr) => {
        acc[curr?.questionId] = curr;
        return acc;
      }, {});

    updateUser({
      uid: userForGeneration?.uid,
      data: {
        book_qa_data: {
          ...(userForGeneration?.book_qa_data || {}),
          ...generatedData,
        },
      },
    }).then(() => {
      fetchUsersData({ s: userForGeneration?.email });
    });

    setReviewModal(false);
    setUserForGeneration({});
    setChapterStart(null);
    setChapterEnd(null);
  };

  const handleSetStatus = (status) => {
    updateUser({
      uid: userForGeneration?.uid,
      data: {
        [status]: Math.round(Date.now() / 1000),
      },
    }).then(() => {
      setUserForGeneration({});
      fetchUsersData({ f: filter });
    });
  };

  const handleUserFilter = (f) => {
    setFilter(f);
    fetchUsersData({ f });
  };

  const handleSetReview = async () => {
    try {
      const url =
        "https://memowrite.app.n8n.cloud/webhook/73453ac2-fd3b-40e3-82ed-996fc3e96a7e";
      const dataToSend = {
        email: userForGeneration?.email,
        language: userForGeneration?.language,
      };

      await fetch(url, {
        method: "POST",
        body: JSON.stringify(dataToSend),
      });
    } catch (error) {
      console.error("Error:", error);
    } finally {
      handleSetStatus("review_book");
    }
  };

  const selectChapterBubbleColor = (text, verified, qId, email) => {
    if (
      startChapter <= qId &&
      endChapter >= qId &&
      userForGeneration?.email === email
    ) {
      return "blue";
    }
    if (verified) {
      return "green";
    }
    if (text === "placeholder") {
      return "grey";
    }
    if (text || text === "skip") {
      return "orange";
    }
    return "#e0e0e0";
  };

  const renderChaptersChart = (user) => {
    const placeholderData =
      user?.email === userForGeneration?.email
        ? structuredUserQuestionsWithAnswers.map((i) => ({
            text: i?.answers?.length > 0 ? "placeholder" : "",
          }))
        : Array(50).fill({ text: "" });
    const chartData = user?.book_qa_data;
    const mergedData = placeholderData?.map((item, idx) => ({
      ...item,
      ...chartData?.[idx + 1],
      questionId: idx + 1,
    }));

    return (
      <div
        style={{
          width: 140,
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: 4,
        }}
      >
        {mergedData
          ?.sort((a, b) => a?.questionId - b?.questionId)
          ?.map((i) => (
            <div
              onClick={() => {
                setChapterStart(i?.questionId);
                setChapterEnd(i?.questionId);
              }}
              style={{
                width: 10,
                height: 10,
                borderRadius: 5,
                cursor: "pointer",
                backgroundColor: selectChapterBubbleColor(
                  i?.text,
                  i?.verified,
                  i?.questionId,
                  user?.email,
                ),
              }}
            ></div>
          ))}
      </div>
    );
  };

  return (
    <>
      <h4>Users</h4>
      <Row className="mb-3">
        <Col xs={12} sm={4}>
          <InputGroup className="mb-3">
            <Form.Control
              placeholder="User email"
              onChange={(e) => {
                setSearchUser(e.target.value);
              }}
            />
            <Button
              variant="dark"
              onClick={() => {
                setFilter(null);
                fetchUsersData({ s: searchUser });
              }}
            >
              Search
            </Button>
          </InputGroup>
        </Col>
        <Col className="pt-1">
          <span style={{ marginRight: 16 }}>Filters:</span>
          <Button
            size="sm"
            variant={filter === "generate_book" ? "dark" : "outline-dark"}
            onClick={() => handleUserFilter("generate_book")}
            style={{ marginRight: 8 }}
          >
            Generate
          </Button>
          <Button
            size="sm"
            variant={filter === "review_book" ? "dark" : "outline-dark"}
            onClick={() => handleUserFilter("review_book")}
            style={{ marginRight: 8 }}
          >
            Review
          </Button>
          <Button
            size="sm"
            variant={
              filter === "ready_for_print_book" ? "dark" : "outline-dark"
            }
            onClick={() => handleUserFilter("ready_for_print_book")}
            style={{ marginRight: 8 }}
          >
            Ready for print
          </Button>
          <Button
            size="sm"
            variant={filter === "print_book" ? "dark" : "outline-dark"}
            onClick={() => handleUserFilter("print_book")}
            style={{ marginRight: 8 }}
          >
            Print
          </Button>
          <Button
            size="sm"
            variant={filter === "ship_book" ? "dark" : "outline-dark"}
            onClick={() => handleUserFilter("ship_book")}
            style={{ marginRight: 8 }}
          >
            Ship
          </Button>
          {/* <Button
            size="sm"
            variant={filter === "custom" ? "dark" : "outline-dark"}
            onClick={() => handleUserFilter("custom")}
            style={{ marginRight: 8 }}
          >
            Custom
          </Button> */}
        </Col>
      </Row>
      {usersLoading ? (
        <Col className="d-flex justify-content-center align-items-center">
          <Spinner animation="border" />
        </Col>
      ) : users ? (
        <Table style={{ fontSize: 14 }}>
          <thead>
            <tr>
              <th
                className="text-light"
                style={{
                  borderRightColor: "white",
                  borderRightWidth: "1px",
                  backgroundColor: "#112423",
                }}
              ></th>
              <th
                className="text-light"
                style={{
                  borderRightColor: "white",
                  borderRightWidth: "1px",
                  backgroundColor: "#112423",
                }}
              >
                <span style={{ fontSize: 12 }}>Email</span>
              </th>
              <th
                className="text-light"
                style={{
                  borderRightColor: "white",
                  borderRightWidth: "1px",
                  backgroundColor: "#112423",
                }}
              >
                <span style={{ fontSize: 12 }}>Platform</span>
              </th>
              <th
                className="text-light"
                style={{
                  borderRightColor: "white",
                  borderRightWidth: "1px",
                  backgroundColor: "#112423",
                }}
              >
                <span style={{ fontSize: 12 }}>Generate</span>
              </th>
              <th
                className="text-light"
                style={{
                  borderRightColor: "white",
                  borderRightWidth: "1px",
                  backgroundColor: "#112423",
                }}
              >
                <span style={{ fontSize: 12 }}>Review</span>
              </th>
              <th
                className="text-light"
                style={{
                  borderRightColor: "white",
                  borderRightWidth: "1px",
                  backgroundColor: "#112423",
                }}
              >
                <span style={{ fontSize: 12 }}>Ready for print</span>
              </th>
              <th
                className="text-light"
                style={{
                  borderRightColor: "white",
                  borderRightWidth: "1px",
                  backgroundColor: "#112423",
                }}
              >
                <span style={{ fontSize: 12 }}>Print</span>
              </th>
              <th
                className="text-light"
                style={{
                  borderRightColor: "white",
                  borderRightWidth: "1px",
                  backgroundColor: "#112423",
                }}
              >
                <span style={{ fontSize: 12 }}>Ship</span>
              </th>
              <th
                className="text-light"
                style={{
                  borderRightColor: "white",
                  borderRightWidth: "1px",
                  backgroundColor: "#112423",
                }}
              >
                <span style={{ fontSize: 12 }}>Chapters</span>
              </th>
              <th
                className="text-light"
                style={{
                  borderRightColor: "white",
                  borderRightWidth: "1px",
                  backgroundColor: "#112423",
                }}
              >
                <span style={{ fontSize: 12 }}>Action</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {users?.map((user) => {
              return (
                <tr>
                  <td className="pt-3 bg-body-tertiary">
                    <div
                      style={{
                        width: 24,
                        height: 24,
                        borderRadius: 12,
                        borderWidth: 3,
                        borderStyle: "solid",
                        borderColor:
                          userForGeneration?.email === user?.email
                            ? "green"
                            : "silver",
                        backgroundColor:
                          userForGeneration?.email === user?.email
                            ? "green"
                            : "transparent",
                        cursor: "pointer",
                      }}
                      onClick={() => setUserForGeneration(user)}
                    ></div>
                  </td>
                  <td className="pt-3 pb-3 bg-body-tertiary">
                    <span style={{ fontSize: 12 }}>{user?.email}</span>
                  </td>
                  <td className="pt-3 pb-3 bg-body-tertiary">
                    <span style={{ fontSize: 12 }}>
                      {user?.platform
                        ? user?.platform
                        : user?.name
                        ? "app"
                        : "email"}
                    </span>
                  </td>
                  <td className="pt-3 bg-body-tertiary">
                    {userForGeneration?.email === user?.email &&
                    !user?.generateBookDate ? (
                      <Button
                        size="sm"
                        variant="outline-dark"
                        onClick={() => handleSetStatus("generate_book")}
                        style={{ fontSize: 12 }}
                      >
                        Set generate
                      </Button>
                    ) : (
                      <span style={{ fontSize: 12 }}>
                        {user?.generateBookDate}
                      </span>
                    )}
                  </td>
                  <td className="pt-3 bg-body-tertiary">
                    {userForGeneration?.email === user?.email &&
                    !user?.review_book ? (
                      <Button
                        size="sm"
                        variant="outline-dark"
                        onClick={handleSetReview}
                        style={{ fontSize: 12 }}
                      >
                        Set review
                      </Button>
                    ) : (
                      <span style={{ fontSize: 12 }}>
                        {user?.reviewBookDate}
                      </span>
                    )}
                  </td>
                  <td className="pt-3 bg-body-tertiary">
                    {userForGeneration?.email === user?.email &&
                    !user?.readyForPrintBookDate ? (
                      <Button
                        size="sm"
                        variant="outline-dark"
                        onClick={() => handleSetStatus("ready_for_print_book")}
                        style={{ fontSize: 12 }}
                      >
                        Set ready for print
                      </Button>
                    ) : (
                      <span style={{ fontSize: 12 }}>
                        {user?.readyForPrintBookDate}
                      </span>
                    )}
                  </td>
                  <td className="pt-3 bg-body-tertiary">
                    {userForGeneration?.email === user?.email &&
                    !user?.printBookDate ? (
                      <Button
                        size="sm"
                        variant="outline-dark"
                        onClick={() => setPrintModal(true)}
                        style={{ fontSize: 12 }}
                      >
                        Set print
                      </Button>
                    ) : (
                      <span style={{ fontSize: 12 }}>
                        {user?.printBookDate}
                      </span>
                    )}
                  </td>
                  <td className="pt-3 bg-body-tertiary">
                    {userForGeneration?.email === user?.email &&
                    !user?.shipBookDate ? (
                      <Button
                        size="sm"
                        variant="outline-dark"
                        onClick={() => setShipModal(true)}
                        style={{ fontSize: 12 }}
                      >
                        Set ship
                      </Button>
                    ) : (
                      <span style={{ fontSize: 12 }}>{user?.shipBookDate}</span>
                    )}
                  </td>
                  <td className="pt-3 bg-body-tertiary">
                    {renderChaptersChart(user)}
                  </td>
                  <td className="pt-3 bg-body-tertiary">
                    {userForGeneration?.email === user?.email ? (
                      emailRepliesLoading ? (
                        <Spinner />
                      ) : (
                        <>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              marginBottom: 8,
                            }}
                          >
                            <Button
                              size="sm"
                              variant="dark"
                              onClick={() => {
                                generate();
                              }}
                              disabled={loading}
                              style={{ fontSize: 12 }}
                            >
                              {loading ? "Generating..." : "Generate chapters"}
                            </Button>
                            <input
                              type="number"
                              value={startChapter}
                              onChange={(e) =>
                                setChapterStart(e?.target?.value)
                              }
                              style={{ width: 40, marginLeft: 4 }}
                              min={1}
                            />
                            <input
                              type="number"
                              value={endChapter}
                              onChange={(e) => setChapterEnd(e?.target?.value)}
                              style={{ width: 40, marginLeft: 4 }}
                              max={50}
                            />
                            <Dropdown>
                              <Dropdown.Toggle
                                id="dropdown-basic"
                                size="sm"
                                variant="outline-dark"
                                style={{ marginLeft: 4 }}
                              ></Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item
                                  style={{
                                    color:
                                      model === "gpt-3.5-turbo"
                                        ? "black"
                                        : "grey",
                                  }}
                                  onClick={() => setModel("gpt-3.5-turbo")}
                                >
                                  gpt-3.5-turbo
                                </Dropdown.Item>
                                <Dropdown.Item
                                  style={{
                                    color: model === "gpt-4" ? "black" : "grey",
                                  }}
                                  onClick={() => setModel("gpt-4")}
                                >
                                  gpt-4
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              marginBottom: 8,
                            }}
                          >
                            <Button
                              size="sm"
                              variant="dark"
                              onClick={() => {
                                getData();
                                setReviewModal(true);
                              }}
                              style={{ fontSize: 12 }}
                            >
                              Review book
                            </Button>
                            <Button
                              size="sm"
                              style={{ marginLeft: 4, fontSize: 12 }}
                              variant="danger"
                              onClick={() => {
                                setEditModal(true);
                              }}
                            >
                              Edit book
                            </Button>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              marginBottom: 8,
                            }}
                          >
                            <Button
                              size="sm"
                              variant="outline-dark"
                              onClick={() => {
                                setClientInfoModal(true);
                              }}
                              style={{ fontSize: 12 }}
                            >
                              View client info
                            </Button>
                            <Button
                              size="sm"
                              style={{ marginLeft: 4, fontSize: 12 }}
                              variant="primary"
                              onClick={() => setSendToPrintModal(true)}
                            >
                              Send to print
                            </Button>
                          </div>
                        </>
                      )
                    ) : null}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FaSearch size={20} style={{ marginRight: 4 }} />
          <span>Try search or filters</span>
        </div>
      )}
      <ReviewModal
        visible={reviewModal}
        onClose={() => setReviewModal(false)}
        onSave={handleSaveGeneratedAnswers}
        bookData={bookData}
        userData={userForGeneration}
      />
      <ClientInfoModal
        visible={clientInfoModal}
        onClose={() => {
          setClientInfoModal(false);
        }}
        onRefresh={() => {
          fetchUsersData({ f: filter, s: searchUser });
          setUserForGeneration({});
        }}
        clientInfo={userForGeneration}
      />
      <ShipModal
        visible={shipModal}
        onClose={() => {
          setShipModal(false);
          fetchUsersData({ f: "ship_book" });
        }}
        uid={userForGeneration?.uid}
        email={userForGeneration?.email}
        language={userForGeneration?.language}
      />
      <PrintModal
        visible={printModal}
        onClose={() => {
          setPrintModal(false);
          fetchUsersData({ f: "print_book" });
        }}
        uid={userForGeneration?.uid}
        email={userForGeneration?.email}
        language={userForGeneration?.language}
      />
      <BookReviewModal
        visible={editModal}
        onClose={() => setEditModal(false)}
        uid={userForGeneration?.uid || currentUser?.uid}
        admin={true}
      />
      <SendToPrintModal
        visible={sendToPrintModal}
        onClose={() => setSendToPrintModal(false)}
        userData={userForGeneration}
      />
    </>
  );
};

export default UsersTable;
