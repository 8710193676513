import { useState } from "react";
import parse from "html-react-parser";
import ImageFromStorage from "./ImageFromStorage";
import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import Button from "react-bootstrap/Button";

const BookHtmlTemplateQA = ({ data, name, editable }) => {
  const [dataState, setDataState] = useState(data);
  const { t } = useTranslation();

  const renderImages = (arraysOfPhotos) => {
    if (arraysOfPhotos?.length > 0) {
      return arraysOfPhotos?.map((item) => {
        return (
          <div key={item?.path}>
            <div
              className="pdf-pagebreak-before"
              style={{
                paddingTop: 30,
                paddingBottom: 30,
                width: 690,
                height: 750,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                position: "relative",
              }}
            >
              <ImageFromStorage
                link={item?.path}
                width={690}
                height={750}
                objectFit="contain"
                data-pdfmake='{"fit": [320, 320] }'
                editable={editable}
                isAdmin={true}
              />
            </div>
            {item?.image_description && (
              <p
                style={{
                  marginTop: 16,
                  fontSize: 14,
                }}
              >
                {item?.image_description}
              </p>
            )}
          </div>
        );
      });
    }

    return null;
  };

  return (
    <>
      <div className="hidden-from-review">
        <h1 className="first-page-title">
          {t("book.myLifeStory").toUpperCase()}
        </h1>
        <span className="author-text">{t("book.by")}</span>
        <span className="author-text">{name}</span>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/memowrite-efa1b.appspot.com/o/memowrite_logo_first_page.png?alt=media&token=7efd9748-d100-424f-aa2f-337623fd4f48"
          data-pdfmake='{"fit": [120, 50]}'
          alt="memowrite logo"
        />
      </div>
      <div className="pdf-pagebreak-before">
        <p> </p>
      </div>
      {dataState?.map(
        (chapterData, idx) =>
          chapterData?.answer &&
          chapterData?.answer !== "skip" && (
            <div key={`chapter_${idx}`}>
              <h4 className="headline pdf-pagebreak-before">
                {chapterData?.questionAsTitle}
              </h4>
              {editable && (
                <Button
                  style={{ alignSelf: "center" }}
                  variant="danger"
                  onClick={() => {
                    const updatedData = [...dataState];
                    updatedData.splice(idx, 1);
                    setDataState(updatedData);
                  }}
                >
                  Remove chapter
                </Button>
              )}
              {parse(chapterData?.answer || "")}
              {renderImages(chapterData?.photos)}
            </div>
          ),
      )}
      <div className="hidden-from-review">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/memowrite-efa1b.appspot.com/o/memowrite_logo_last_page.png?alt=media&token=05f590af-69c7-4ecc-8d1f-005b431f8f15"
          data-pdfmake='{"fit": [80, 20]}'
          alt="memowrite logo in black background"
          className="pdf-pagebreak-before last-page-logo"
        />
        <span className="last-page-text">
          {t("book.publishedBy")} Memowrite, {format(new Date(), "yyy")}
        </span>
        <span style={{ marginBottom: 20 }} className="last-page-text">
          <b>getmemowrite.com</b>
        </span>
        <span className="last-page-text">
          {t("book.printedInUnitedStates")}
        </span>
      </div>
    </>
  );
};

export default BookHtmlTemplateQA;
